import React, {useEffect} from 'react';

function Index() {
  useEffect(() => {
    window.location.replace(
      'https://center-takmed.webflow.io/'
    );
  });

  return null;
}

export default Index;
